import { useEffect, useRef, useState } from "react"
import servicesApi from "../services/servicesApi"

const Services = (props) => {
  const setRoute = props.setRoute

  const [services,setServices] = useState([])
  const servicesRef = useRef(services)
  const setServicesRef = (data) => {
    servicesRef.current = data
    setServices(data)
  }

  const setTextColor = (color) => {
    var c = color.substring(1)
    var rgb = parseInt(c, 16)
    var r = (rgb >> 16) & 0xff
    var g = (rgb >>  8) & 0xff
    var b = (rgb >>  0) & 0xff
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; 
    if (luma < 155) return 'white'
    else return 'black'
  }

  useEffect(()=>{
    setRoute("Services")
  },[setRoute])

  useEffect(()=>{
    servicesApi.get().then(resp => {setServicesRef(resp)})
  },[])

  return (<div className="page services">
    <h1 className="servicesTitle">Services</h1>
    {servicesRef.current && servicesRef.current.map((e,k)=><div className={"service "+(e.large ? 'large' : 'small')} key={k+'-'+e.id}>
      <div className="image">
        {servicesApi.getImg((e.large ? 'l' : 's'),e.picture)}
      </div>
      <div className="texte" style={{backgroundColor:e.background_color?e.background_color:"transparent",color:e.background_color?setTextColor(e.background_color):"white"}}>
        <div className="text-container">
          <h4>{e.name}</h4>
          <p>{e.description}</p>
          <span>A partir de {e.price}€</span>
        </div>
      </div>
    </div>)}
  </div>)
}

export default Services
import { useState } from "react"
import { useEffect } from "react"
import dataApi from "../services/dataApi"

const APropos = (props) => {
  const setRoute = props.setRoute
  const [data,setData] = useState({})

  useEffect(()=>{
    setRoute("A Propos")
  },[setRoute])

  useEffect(()=>{
    dataApi.getAbout().then(resp => {setData(resp)})
  },[])

  return (<div className="page about">
    <div className="text">
      {data.text && <span dangerouslySetInnerHTML={{__html:data.text}}></span>}
    </div>
    <div className="photo">
      {data.picture && dataApi.getAboutPicture(data.picture)}
    </div>
  </div>)
}

export default APropos
import Axios from 'axios'
import config from '../config/api.config'

async function getRange(page=1,limit=5,optionnal={}) {
  let cplt = "?limit="+limit+"&page="+page+(optionnal.buyable ? "&buyable=true" : "")+(optionnal.strict ? "&strict=true" : "")
  let resp = await Axios.get(config.api+"p/images"+cplt)
  return resp.data
}

function get(format,name,password="") {
  // let resp = await Axios.get(config.api+"p/images/"+format+"/"+name+(password!==""?"?password="+password:""))
  // return resp.data
  return <img src={config.api+"p/images/"+format+"/"+name+(password!==""?"?password="+password:"")} alt={name} />
}

const picturesApi = {
  getRange,
  get
}
export default picturesApi
import { useEffect, useRef, useState } from "react"
import picturesApi from "../services/picturesApi"

const Shop = (props) => {
  const setRoute = props.setRoute

  const [pictures,setPictures] = useState([])
  const picturesRef = useRef(pictures)
  const setPicturesRef = (data) => {
    picturesRef.current = data
    setPictures(data)
  }

  const [scrollLoading,setScrollLoading] = useState(false)
  const scrollLoadingRef = useRef(scrollLoading)
  const setScrollLoadingRef = (data) => {
    scrollLoadingRef.current = data
    setScrollLoading(data)
  }

  const [page,setPage] = useState(1)
  const pageRef = useRef(page)
  const setPageRef = (data) => {
    pageRef.current = data
    setPage(data)
  }

  const [bigPicture,setBigPicture] = useState("")
  const [bigActive,setBigActive] = useState(false)

  var limit = 10

  const eventScroll = (e)=>{
    let bodyHeight = document.body.clientHeight
    let windowHeight = window.innerHeight
    let scrollHeight = window.scrollY
    if(bodyHeight<(scrollHeight+windowHeight+500)) {
      if(!scrollLoadingRef.current) {
        setScrollLoadingRef(true)
        if(picturesRef.current.length % limit === 0) picturesApi.getRange(pageRef.current+1,limit,{buyable:true,strict:true}).then(resp => {
          if(resp.length !==0) {
            setPageRef(pageRef.current+1)
            let pics = [...picturesRef.current]
            pics = pics.concat(resp)
            setPicturesRef(pics)
          }
          setScrollLoadingRef(false)
        })
      }
    }
  }

  const openBig = (p) => {
    setBigPicture(p)
    setBigActive(true)
  }

  useEffect(()=>{
    setRoute("Boutique")
  },[setRoute])

  useEffect(()=>{
    picturesApi.getRange(pageRef.current,limit,{buyable:true,strict:true}).then(resp => {setPicturesRef(resp)})
    window.addEventListener('scroll',eventScroll)

    return () => {window.removeEventListener('scroll',eventScroll)}
  },[])

  return (<>
    <div className="page pictures shop">
      <h2 className="shopTitle">Pour tout achat contactez moi par mail !</h2>
      {pictures.map((e,k)=><div className={"picture "+(e.large_h ? "large-h " : "")+(e.large_w ? "large-w" : "")} key={k+"-"+e.id} data-id={e.id} onClick={()=>{openBig(e)}}>
        {picturesApi.get("m",e.file)}
      </div>)}
    </div>
    {bigActive && <div className="bigPictures" onClick={() => {setBigActive(false)}}>
      {picturesApi.get(bigPicture.buyable ? "l" :"src",bigPicture.file)}
    </div>}
  </>)
}

export default Shop
import Axios from 'axios'
import config from '../config/api.config'


async function getAll() {
  let resp = await Axios.get(config.api+"p/gallery")
  return resp.data
}

async function get(id,password="") {
  let resp = await Axios.get(config.api+"p/gallery/"+id+(password!==""?"?password="+password:""))
  return resp.data
}

const galleriesApi = {
  getAll,
  get
}
export default galleriesApi
import Axios from 'axios'
import config from '../config/api.config'

async function getContact() {
  let resp = await Axios.get(config.api+"contact")
  return resp.data
}

async function sendContact(data) {
  try {
    let resp = await Axios.post(config.api+"contact/send",data)
    console.log(resp)
    return resp.data
  } catch (error) {
    throw error
  }
}

async function getAbout() {
  let resp = await Axios.get(config.api+"about")
  return resp.data
}

function getAboutPicture(d){
  return <img src={config.api+"about/"+d} alt={d} />
}


const dataApi = {
  getContact,
  sendContact,
  getAbout,
  getAboutPicture
}
export default dataApi
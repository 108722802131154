import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import galleriesApi from "../services/galleriesApi"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTicket } from "@fortawesome/free-solid-svg-icons"
import picturesApi from "../services/picturesApi"

const Gallery = (props) => {
  const setRoute = props.setRoute
  const params = useParams()
  const [name,setName] = useState(params.name)
  const id = params.id
  const [data,setData] = useState({})
  const [needPassword,setNeedPassword] = useState(false)
  const [password,setPassword] = useState("")

  const [bigPicture,setBigPicture] = useState("")
  const [bigActive,setBigActive] = useState(false)

  useEffect(()=>{
    setRoute(name)
  },[setRoute,name])

  useEffect(()=>{
    setName(params.name)
  },[params.name])

  useEffect(()=>{
    galleriesApi.get(id).then(resp=>{
      if(name!==resp.name) setRoute(resp.name)
      setData(resp)
    }).catch(error => {
      if(error.response && error.response.data && error.response.data.code && error.response.data.code === "gallery.get.notAuthorized" ) setNeedPassword(true)
    })
  },[name,id])

  const getPrivateGallery = () => {
    if(needPassword) {
      galleriesApi.get(id,password).then(resp=>{
        if(name!==resp.name) setRoute(resp.name)
        setNeedPassword(false)
        setData(resp)
      }).catch(error => {
        if(error.response && error.response.data && error.response.data.code && error.response.data.code === "gallery.get.notAuthorized" ) setNeedPassword(true)
      })
    }
  }

  const openBig = (p) => {
    setBigPicture(p)
    setBigActive(true)
  }

  return (needPassword ? 
  <div className="page wrapper">
    <div className="pageBody galleryForm">
      <div className="form">
        <h1>Cette galerie a besoin un mot de passe</h1>
        <div className=" form-input">
          <input type="text" name="gallery_password" id="gallery_password" autoComplete="off" value={password} onChange={(e) => {setPassword(e.target.value)}}/><button onClick={getPrivateGallery}><FontAwesomeIcon icon={faTicket} /></button>
        </div>
      </div>
    </div>
  </div>
  :
  <>
    <div className="page pictures gallery" key={name+Date.now()}>
      <h1 className="galleryTitle">{name}</h1>
      {data && data.pictures && data.pictures.map((e,k)=><div className={"picture "+(e.large_h ? "large-h " : "")+(e.large_w ? "large-w" : "")} key={name+"-"+k+"-"+e.id} onClick={()=>{openBig(e)}}>
        {picturesApi.get("m",e.file,password)}
      </div>)}
    </div>
    {bigActive && <div className="bigPictures" onClick={() => {setBigActive(false)}}>
      {picturesApi.get(bigPicture.buyable ? "l" :"src",bigPicture.file,password)}
    </div>}
  </>)
}

export default Gallery
import Axios from 'axios'
import config from '../config/api.config'

async function get() {
  let resp = await Axios.get(config.api+"p/services")
  return resp.data
}

function getImg(format,name) {
  // let resp = await Axios.get(config.api+"p/images/"+format+"/"+name+(password!==""?"?password="+password:""))
  // return resp.data
  return <img src={config.api+"p/services/images/"+format+"/"+name} alt={name} />
}

const servicesApi = {
  get,
  getImg
}
export default servicesApi
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"
import './App.css'
import moment from "moment"
import { useEffect, useState } from "react"
import galleriesApi from "./services/galleriesApi"
import Home from './pages/Home'
import Photos from "./pages/Photos"
import APropos from "./pages/APropos"
import Services from "./pages/Services"
import Contact from "./pages/Contact"
import Shop from "./pages/Shop"
import Gallery from "./pages/Gallery"
import Page404 from "./pages/Page404"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { Fri9gNotifier,fri9g_notify } from 'fri9g-notification-react'
import 'fri9g-notification-react/dist/index.css'
import axios from 'axios'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = `https://linstantbydavid.com`

function App() {
  const formatDate = (str) => moment(str).format('YYYY')
  const [menuActive,setMenuActive] = useState(false)
  const [galleries,setGalleries] = useState([])
  const [route,setRoute] = useState('Accueil')
  
  useEffect(()=>{
    galleriesApi.getAll().then(resp => setGalleries(resp))
  },[])
  
  return (
    <>
      <Router>
        <div className="App">
          <nav className={menuActive ? "navbar active" : "navbar"}>
            <div className="navbar-logo">
              <img src="/images/logo_light.png" alt="japan candy logo" />
            </div>
            <div className="navbar-spacer"></div>
            <div className="navbar-item">
              <button className="navbar-menu-button" onClick={()=>{setMenuActive(!menuActive)}}>{route} <FontAwesomeIcon icon={faChevronDown} /></button>
              {/* insert burger to open for mobile */}
              <div className={menuActive ? "navbar-menu-dropdown active" : "navbar-menu-dropdown"}>
                {/* insert cross to close for mobile */}
                <button className="navbar-menu-item"><Link to="/" onClick={()=>{setMenuActive(!menuActive)}}>Accueil</Link></button>
                {/* <button className="navbar-menu-item"><Link to="/photos" onClick={()=>{setMenuActive(!menuActive)}}>Photos</Link></button> */}
                {galleries.map((e,k)=><button className="navbar-menu-item" key={k+"-"+e.id}><Link to={"/galerie/"+e.id+"/"+e.name} onClick={()=>{setMenuActive(!menuActive)}}>{e.name}</Link></button>)}
                <button className="navbar-menu-item"><Link to="/contact" onClick={()=>{setMenuActive(!menuActive)}}>Contact</Link></button>
                <button className="navbar-menu-item"><Link to="/services" onClick={()=>{setMenuActive(!menuActive)}}>Services</Link></button>
                <button className="navbar-menu-item"><Link to="/shop" onClick={()=>{setMenuActive(!menuActive)}}>Boutique</Link></button>
                <button className="navbar-menu-item"><Link to="/a-propos" onClick={()=>{setMenuActive(!menuActive)}}>A propos</Link></button>
              </div>
            </div>
          </nav>
          <Routes>
            <Route path="/" element={<Home setRoute={setRoute}/>}/>
            {/* <Route path="/photos" element={<Photos setRoute={setRoute}/>}/> */}
            <Route path="/a-propos" element={<APropos setRoute={setRoute}/>}/>
            <Route path="/services" element={<Services setRoute={setRoute}/>}/>
            <Route path="/contact" element={<Contact setRoute={setRoute}/>}/>
            <Route path="/shop" element={<Shop setRoute={setRoute}/>}/>
            <Route path="/galerie/:id/:name" element={<Gallery setRoute={setRoute}/>}/>
            <Route path='*' element={<Page404 setRoute={setRoute}/>}/>
          </Routes>
          <div className="footer">
            <div className="footer-copy-right">
              <p>Copyright &copy; <Link to="https://www.fri9g.com">Fri9g</Link> - { formatDate() } </p>
            </div>
          </div>
        </div>
      </Router>
      <Fri9gNotifier position={fri9g_notify.POSITION.BOTTOM_LEFT}/>
    </>
  )
}

export default App;

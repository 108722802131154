import { faFacebook, faInstagram, faLinkedin, faTiktok } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import dataApi from "../services/dataApi"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faLink, faPhoneVolume } from "@fortawesome/free-solid-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"


const Contact = (props) => {
  const setRoute = props.setRoute
  const [data,setData] = useState({})
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [message,setMessage] = useState("")

  useEffect(()=>{
    setRoute("Contact")
  },[setRoute])

  useEffect(()=>{
    dataApi.getContact().then(resp => {setData(resp)})
  },[])

  const sendContact = async (e) => {
    e.preventDefault()
    let sendingData = {name,email,message}
    if(!name || !email || !message) {
      fri9g_notify.warning("Vous devez remplir tous les champs pour envoyer une demande de contact")
      return
    }
    try {
      await dataApi.sendContact(sendingData)
      setName("")
      setEmail("")
      setMessage("")
      fri9g_notify.success("Formulaire de contact envoyé")
    } catch (error) {
      console.log(error)
      fri9g_notify.error("Erreur lors de l'envoi du formulaire")
    }
  }

  return (<div className="contact page">
    <div className="contact-section">
      <h1>Ou par d'autres moyens</h1>
      <p>Pour toute demande ou renseignement, n'hésitez pas à me contacter via les moyens suivants</p>
      
      <ul className="contact-list">
        {data.email && <li><FontAwesomeIcon icon={faEnvelope} /><a href={"mailto:"+data.email}>{data.email}</a></li>}
        {data.phone && <li><FontAwesomeIcon icon={faPhoneVolume} /><span>{data.phone}</span></li>}
      </ul>
      
      <div className="social-media-links">
        <span>Suivez-moi sur :</span>
        <ul>
          {data.socials && data.socials.map((e,k) => {
              var icon
              switch (e.type){
                case "instagram" : 
                  icon = <FontAwesomeIcon icon={faInstagram} />
                  break
                case "facebook" :
                  icon = <FontAwesomeIcon icon={faFacebook} />
                  break
                case "linkedin" : 
                  icon = <FontAwesomeIcon icon={faLinkedin} />
                  break
                case "tiktok" : 
                  icon = <FontAwesomeIcon icon={faTiktok} />
                  break
                default: 
                  icon = <FontAwesomeIcon icon={faLink} />
              }
              return <li key={k}><a href={e.link} target="_blank" rel="noreferrer">{icon}</a></li>
          })}
        </ul>
      </div>
    </div>
    <div className="contact-form">
      <h1>Via Formulaire</h1>
      <form >
        <div className="form-group">
          <label for="name">Nom</label>
          <input type="text" id="name" name="name" value={name} onChange={e => {e.preventDefault(); setName(e.target.value)}} required/>
        </div>
        <div className="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" name="email" value={email} onChange={e => {e.preventDefault(); setEmail(e.target.value)}} required/>
        </div>
        <div className="form-group">
          <label for="message">Message</label>
          <textarea id="message" name="message" value={message} onChange={e => {e.preventDefault(); setMessage(e.target.value)}} required></textarea>
        </div>
        <button type="submit" onClick={e => sendContact(e)}>Envoyer</button>
      </form>
    </div>
  </div>)
}

export default Contact